import * as React from "react";
import "./TitleBlock.scss";

export default function TitleBlock() {
  return (
    <div className="title-block">
      <div className="container">
        <h2>Ansioluettelo</h2>
      </div>
    </div>
  );
}
